import React, { useEffect } from "react";
import Modal from "../Modal";

const NotificationModal = props => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  useEffect(() => {
    // Update the document title using the browser API
    const timer = setTimeout(() => {
      openModal();
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Upozornění"
      modalType="notification"
    >
      {props.children}
    </Modal>
  );
};

export default NotificationModal;
