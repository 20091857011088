import React, { useState, useRef, useEffect } from "react";
import { Box, Flex } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { AdvantageBlockWrapper, WhyAgentTop, AdvantageBlockTopHeading, WhyAgentItem } from "../../style/AdvantageBlock";
import { Image } from "../../style/Image";
import { WhyAgentItemTitleMobile } from "../../style/AdvantageBlock/WhyAgentItemTitleMobile";
import { WhyAgentItemTextMobile } from "../../style/AdvantageBlock/WhyAgentItemTextMobile";
import AdvantageBlockBottom from "./AdvantageBlockBottom";
import { WhyGarantion, WhyLawyer, WhySavings, WhySolution, WhyTime } from "../../assets/Svg";

const advantages = [
  {
    image: WhyTime,
    name: "Úspora času",
    text:
      "Čas je jediné aktivum, kterého má každý na světě za jeden den stejně. A&nbsp;díky spolupráci s&nbsp;poradcem KAPITOLu, který za vás oběhne a&nbsp;zjistí potřebné informace u&nbsp;bank, pojišťoven či&nbsp;jiných finančních institucí napříč celým trhem, ušetříte nejen mnoho energie, ale především zmiňovaného času.",
  },
  {
    image: WhySavings,
    name: "Úspora nákladů",
    text:
      "Při výběru z&nbsp;nabízených možností řešení vašich potřeb vás naši poradci rádi upozorní na výhody či různá jiná specifika pojištění či finančních produktů, díky kterým můžete do budoucna ušetřit náklady nebo zhodnotit vaše portfolio o&nbsp;nemalé peníze.",
  },
  {
    image: WhySolution,
    name: "Návrh řešení na míru",
    text:
      "Naši poradci spolupracuji při hledání optimálního řešení vašich potřeb s&nbsp;celou řadou zkušených odborníků a&nbsp;specialistů na centrále KAPITOLu. U&nbsp;některých situací za vás dokonce jednají přímo s&nbsp;konkrétními pracovníky dané banky či pojišťovny. Díky tomu získáte kvalitní nabídku produktů a&nbsp;služeb ušitých na míru jen vám.",
  },
  {
    image: WhyLawyer,
    name: "Právní zastoupení",
    text:
      "Máte pocit, že jste v&nbsp;rámci plnění pojistné události dostali nižší odškodné, než na které máte dle platné smlouvy nárok? Naši poradci vám v&nbsp;těchto situacích dokážou zajistit bezplatné právní poradenství.",
  },
  {
    image: WhyGarantion,
    name: "Garance doživotní péče a&nbsp;servisu",
    text:
      "Pro naše klienty máme garanci doživotního servisu. V&nbsp;případě ukončení obchodního vztahu s&nbsp;konkrétním poradcem vám vždy bezodkladně přiřadíme nového zkušeného odborníka z&nbsp;vašeho regionu, abyste mohli využít naše služby v&nbsp;každé životní situaci. ",
  },
];

const AdvantageBlock = ({ bottomContent }) => (
  <AdvantageBlockWrapper>
    <WhyAgentTop>
      <AdvantageBlockTopHeading uppercase>Proč je dobré mít poradce?</AdvantageBlockTopHeading>

      {advantages.map((advantage, index) => {
        const [open, setOpen] = useState(false);
        const [show, setShow] = useState(false);
        const itemRef = useRef(null);

        useEffect(() => {
          function showDart() {
            if (itemRef.current.childNodes[0].offsetHeight >= itemRef.current.offsetHeight) {
              setShow(true);
            } else {
              setShow(false);
            }
          }
          window.addEventListener("resize", showDart);
          showDart();
          return function cleanup() {
            window.removeEventListener("resize", showDart);
          };
        });

        return (
          <WhyAgentItem key={index}>
            <Image src={advantage.image} width={["42px", null, "81px"]} mr={[3, null, 4]} flexShrink={0} />
            <Flex flexDirection="column">
              <Box display={["none", "block"]}>
                <Heading
                  uppercase
                  as="h3"
                  mb={2}
                  fontSize={["18px", null, "28px"]}
                  fontWeight="light"
                  color="white.100"
                  dangerouslySetInnerHTML={{ __html: advantage.name }}
                />
                <Text color="whyAgent.text" fontSize={["15px", null, 2]} dangerouslySetInnerHTML={{ __html: advantage.text }} />
              </Box>

              <Box display={["block", "none"]}>
                <WhyAgentItemTitleMobile onClick={() => setOpen(!open)} open={open} show={show} dangerouslySetInnerHTML={{ __html: advantage.name }} />
                <WhyAgentItemTextMobile open={open} ref={itemRef}>
                  <span dangerouslySetInnerHTML={{ __html: advantage.text }} />
                </WhyAgentItemTextMobile>
              </Box>
            </Flex>
          </WhyAgentItem>
        );
      })}
    </WhyAgentTop>

    <AdvantageBlockBottom bottomContent={bottomContent} />
  </AdvantageBlockWrapper>
);

export default AdvantageBlock;
