import React from "react";
import { Heading } from "../../style/Heading";
import { Text } from "../../style/Text";
import { Button } from "../../style/Button";
import Link from "../Link";
import { AdvantageBlockBottom as AdvantageBlockBottomStyle } from "../../style/AdvantageBlock";
import { htmlDecode } from "../../utils";
import { URLS } from "../../const/const";

const AdvantageBlockBottom = ({ bottomContent }) => (
  <AdvantageBlockBottomStyle>
    <Heading
      as="h3"
      mb={2}
      fontWeight="light"
      fontSize={[4, null, 5]}
      uppercase
    >
      {bottomContent?.title ?? "Chcete udělat dobré rozhodnutí?"}
    </Heading>
    <Text as="p" mb={4} lineHeight="1" maxWidth={325}>
      {htmlDecode(
        bottomContent?.text ??
          "Napište nám, s&nbsp;čím potřebujete poradit a&nbsp;my si s&nbsp;vámi rádi domluvíme schůzku"
      )}
    </Text>
    <Button
      variant={"primary"}
      as={Link}
      href={bottomContent?.cta?.url ?? URLS.contactForm}
    >
      {bottomContent?.cta?.text ?? "Ozvěte se nám"}
    </Button>
  </AdvantageBlockBottomStyle>
);

export default AdvantageBlockBottom;
