import styled from "styled-components";

import { Flex } from "../Grid";

const WhyAgentItemTitleMobile = styled(Flex)`
  position: relative;
  width: 100%;
  padding: 0 30px 0 0;
  background-color: transparent;
  border: 0;
  text-transform: uppercase;
  text-align: left;
  cursor: pointer;
  &:after {
    visibility: ${props => (props.show || props.open ? "visible" : "hidden")};
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
    content: "";
    width: 25px;
    height: 9px;
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='9' viewBox='0 0 25 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 0.999999L13 8' stroke='%2300B8A6' stroke-width='2' stroke-miterlimit='10'/%3E%3Cpath d='M12 8L24 1' stroke='%2300B8A6' stroke-width='2' stroke-miterlimit='10'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    transition: 400ms;
    transform: rotate(${props => (props.open ? "180deg" : "0deg")});
  }
  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`;

WhyAgentItemTitleMobile.displayName = "WhyAgentItemTitleMobile";

WhyAgentItemTitleMobile.defaultProps = {
  as: "button",
  mb: 2,
  color: "white.100",
  fontSize: ["18px", null, "28px"],
  fontWeight: "light",
};

export { WhyAgentItemTitleMobile };
