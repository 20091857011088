import React from "react";
import { Loader as LoaderStyle } from "../../style/Loader";
import { Box } from "../../style/Grid";

const Loader = ({ variant, minHeight }) => (
  <LoaderStyle variant={variant ?? "contact"} minHeight={minHeight ?? "auto"}>
    <Box />
    <Box />
    <Box />
  </LoaderStyle>
);

export default Loader;
