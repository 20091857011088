import styled from "styled-components";
import { variant } from "styled-system";

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-height: 16px;
  text-align: center;

  ${props =>
    variant({
      variants: {
        contact: {
          width: 54,
          "& > div": {
            width: 14,
            height: 14,
            backgroundColor: "white.100",
          },
        },
        storyLoader: {
          width: 114,
          "& > div": {
            width: 30,
            height: 30,
            backgroundColor: "panelPrimary",
            ["@media screen and (max-width: 400px)"]: {
              backgroundColor: "white.100",
            },
          },
        },
      },
    })}

  min-height: ${props => `${props.minHeight}px` ?? "auto"};

  & > div {
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  & > div:first-of-type {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  & > div:nth-of-type(2n) {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

Loader.displayName = "Loader";

Loader.propTypes = {};

export { Loader };
