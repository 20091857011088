import React, { useEffect, useRef, useState } from "react";
import { graphql } from "gatsby";

import Link from "../../components/Link";
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import Header from "../../components/Header";
import { HeroHp } from "../../components/HeroV2";
import StoryBlockSlider from "../../components/StoryBlock/StoryBlockSlider";
import AdvantageBlock from "../../components/AdvantageBlock";
import MagazineBlock from "../../components/MagazineBlock";
import AboutKapitolBlock from "../../components/AboutKapitolBlock";
import ChristopherFoundation from "../../components/ChristopherFoundation";
import NotificationModal from "../../components/NotificationModal";

import { Box, Container, Flex, Wrapper } from "../../style/Grid";
import { Heading } from "../../style/Heading";
import { Button } from "../../style/Button";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import { URLS } from "../../const/const";
import { randomizeArray } from "../../utils";
import CareerSites from "../../components/CareerSites";

const HpTemplate = ({
  data: {
    wp: {
      page: {
        title,
        modal,
        featuredImage,
        pageContent: { banner, selectedStoriesGeneral: stories },
        seo: { metaDesc },
      },
      posts: { nodes: posts },
    },
  },
}) => {


  return (
    <Layout>
      <SEO title={title} description={metaDesc} />
      <Header variant="light" />

      <HeroHp content={banner} image={featuredImage} />

      <Wrapper>
        <Container flexDirection={"column"}>
          <Heading as="h2" mb={[2, null, 4]} fontSize={[4, null, 5]} lineHeight={0} color="headlines" fontWeight="light" letterSpacing="caps">
            PORADÍME VÁM V TĚCHTO OBLASTECH
          </Heading>
        </Container>
      </Wrapper>

      <CareerSites type={"hp"} />

      <Box as={"br"} display={["none", null, "block"]} />
      <br />

      {stories && (
        <>
          <Wrapper>
            <Container flexDirection="row" justifyContent={"space-between"}>
              <Flex width={[1, null, null, "70%"]} maxWidth={["600px", null, null, "70%"]} pb={[0, null, null, 0]}>
                <Heading as="h2" mb={[2, null, 4]} fontSize={[4, null, 5]} lineHeight={0} color="headlines" fontWeight="light" letterSpacing="caps">
                  JAK JSME POMOHLI NAŠIM KLIENTŮM
                </Heading>
              </Flex>
            </Container>
          </Wrapper>

          <StoryBlockSlider stories={randomizeArray(stories)} showLeftPane showProducts />

          <Wrapper backgroundColor={["storyBlock.background", null, null, "transparent"]}>
            <Container justifyContent={"center"}>
              <Button mt={[3, null, null, 4]} mb={["mobileSpacer", null, 48]} variant="secondary" as={Link} href={URLS.stories}>
                Další příběhy
              </Button>
            </Container>
          </Wrapper>
        </>
      )}

      <Wrapper>
        <Container>
          <AdvantageBlock bottomContent={{ title: "Pomůžeme vám udělat dobré rozhodnutí" }} />
        </Container>

        <ChristopherFoundation pt={["mobileSpacer", null, null, 83]} pb={[4, null, null, 64]} />

        <AboutKapitolBlock />

        <Container>
          <MagazineBlock posts={posts} />
        </Container>
      </Wrapper>
      {modal && modal.modalShow && (
        <NotificationModal>
          <Flex width={1} bg="white.100" overflow="hidden" borderRadius={8} flexDirection={["column", null, "row"]}>
            <Box maxWidth={["100%", null, 320]} width={1} py={40} px={20}>
              <Image src={modal?.image?.sourceUrl} width={1} display={["block", null, "none"]} mb={3} />
              <Heading as="h3" lineHeight={1} mb={3} fontSize={28}>
                {modal.modalTitle && modal.modalTitle}
              </Heading>
              <Text color="headlines" fontSize={18} lineHeight={1}>
                {modal.modalPerex && modal.modalPerex}
              </Text>
              <Text lineHeight={1} my={3}>
                {modal.modalText && modal.modalText}
              </Text>
              {modal.modalCta && (
                <Button display="inline-block" variant="secondary" as={Link} href={modal.modalCta.url} target={modal.modalCta.target} alignSelf="center">
                  {modal.modalCta.title}
                </Button>
              )}
            </Box>
            <Box
              display={["none", null, "block"]}
              backgroundImage={`url(${modal.image.sourceUrl})`}
              width={1}
              backgroundSize="cover"
              backgroundPosition="top center"
            />
          </Flex>
        </NotificationModal>
      )}
    </Layout>
  );
};
export default HpTemplate;

export const pageQuery = graphql`
  query HP_PAGE_QUERY {
    wp {
      page(id: "hp", idType: URI) {
        title
        modal {
          modalShow
          modalTitle
          modalPerex
          modalText
          modalCta {
            target
            title
            url
          }
          image {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        featuredImage {
          node {
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        pageContent {
          banner {
            fieldGroupName
            title
            titletop
            cta {
              target
              title
              url
            }
            content
          }
          template
          selectedStoriesGeneral {
            ...StoryInformation
          }
        }
        seo {
          metaDesc
        }
      }
      posts(first: 4) {
        nodes {
          ...PostInformation
        }
      }
    }
  }
`;
