import React from "react";
import { Container, Flex, Wrapper } from "../../style/Grid";
import { Text } from "../../style/Text";
import { Image } from "../../style/Image";
import { Heading } from "../../style/Heading";
import Link from "../Link";
import { URLS } from "../../const/const";
import Icon from "../../style/Icon";
import { AboutVigLogo, KapitolFooterLogo, KapitolLogo } from "../../assets/Svg";

const AboutKapitolBlock = () => (
  <Wrapper bg="panelSecondary" mb={["mobileSpacer", null, 5]}>
    <Container>
      <Flex width="100%" flexDirection={["column", null, "row"]}>
        <Flex maxWidth={["100%", null, "700px"]} width="100%" mt={["mobileSpacer", null, 5]} mb={[0, null, 5]}>
          <div>
            <Heading fontWeight="light" as="h3" mb={3} lineHeight={0} fontSize={[4, null, 5]}>
              O&nbsp;KAPITOLU
            </Heading>

            <Text mb="1em" fontSize={3} lineHeight={1}>
              Jsme česká společnost s&nbsp;bohatou historií od roku 1995 a&nbsp;patříme do&nbsp;významné finanční skupiny Vienna Insurance Group (VIG). Díky
              zázemí světově uznávaného koncernu si můžete být jisti stabilitou, seriózností a&nbsp;důvěryhodností společnosti KAPITOL.
            </Text>

            <Text lineHeight={2}>
              Během své existence jsme si vybudovali široké zázemí a&nbsp;díky zkušenostem i&nbsp;kvalitním partnerům patříme mezi hlavní představitele
              na&nbsp;trhu finančního poradenství. Naším cílem je spokojenost a&nbsp;důvěra našich klientů, což jsou základní předpoklady k&nbsp;dlouhodobé
              a&nbsp;vzájemně prospěšné spolupráci.
            </Text>

            <Link mt={38} variant="smallBorderTop" href={URLS.about} target="_blank">
              Více o&nbsp;kapitolu
              <Icon mb={"-1px"} ml={1} icon="icon-next" size={10} color="cta" />
            </Link>
          </div>
        </Flex>

        <Flex flexGrow={1} flexDirection="column" alignItems="center" justifyContent="center" pl={[0, null, 3]}>
          <Image my={5} width={275} maxWidth="100%" src={KapitolLogo} alt="KAPITOL, a.s." />

          <Image mb="mobileSpacer" width={175} maxWidth="100%" src={AboutVigLogo} alt="VIG - vienna insurance group" />
        </Flex>
      </Flex>
    </Container>
  </Wrapper>
);

export default AboutKapitolBlock;
