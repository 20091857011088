import styled from "styled-components";
import { space } from "styled-system";
import { Flex } from "../Grid";

const AdvantageBlockBottom = styled(Flex)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${space}
`;

AdvantageBlockBottom.displayName = "WhyAgentBottom";

AdvantageBlockBottom.defaultProps = {
  pt: [3],
  pb: [4],
  px: 2,
};

export { AdvantageBlockBottom };
