import styled from "styled-components";

import { Heading } from "../Heading";

const AdvantageBlockTopHeading = styled(Heading)``;

AdvantageBlockTopHeading.displayName = "AdvantageBlockTopHeading";

AdvantageBlockTopHeading.defaultProps = {
  as: "h2",
  mb: 4,
  fontSize: [4, null, 5],
  lineHeight: ["24px", null, "38px"],
  textTransform: "uppercase",
  color: "cta",
  fontWeight: "light",
};

export { AdvantageBlockTopHeading };
