import styled from "styled-components";

import { Flex } from "../Grid";

const WhyAgentItemTextMobile = styled(Flex)`
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.open ? "none" : 3)};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

WhyAgentItemTextMobile.displayName = "WhyAgentItemTextMobile";

WhyAgentItemTextMobile.defaultProps = {
  color: "whyAgent.text",
  fontSize: ["15px", null, 2],
};

export { WhyAgentItemTextMobile };
