import styled from "styled-components";
import { Flex } from "../Grid";

const AdvantageBlockWrapper = styled(Flex)``;

AdvantageBlockWrapper.displayName = "WhyAgentWrapper";

AdvantageBlockWrapper.defaultProps = {
  mx: ["-15px", null, "-31px", null, "0"],
  flexDirection: "column",
  boxShadow: "one",
  borderBottomRightRadius: { xl: 1 },
  borderBottomLeftRadius: { xl: 1 },
};

export { AdvantageBlockWrapper };
