import styled from "styled-components";
import { space } from "styled-system";
import { themeGet } from "@styled-system/theme-get";

import { rgba } from "polished";
import { AdvantageBlockItem } from "./AdvantageBlockItem";

const WhyAgentItem = styled(AdvantageBlockItem)`
  border-bottom: 1px solid
    ${props => rgba(themeGet("colors.whyAgent.line")(props), 0.3)};
  align-items: flex-start;
  ${space};
`;

WhyAgentItem.displayName = "WhyAgentItem";

WhyAgentItem.defaultProps = {
  mb: [4],
  pb: [4],
};

export { WhyAgentItem };
