import styled from "styled-components";
import { Flex } from "../Grid";
import themeGet from "@styled-system/theme-get";

const AdvantageBlockTop = styled(Flex)`
  position: relative;
  flex-direction: column;
  background: ${themeGet("colors.panelDark")};
  color: ${themeGet("colors.white.100")};
`;

AdvantageBlockTop.displayName = "AdvantageBlockTop";

AdvantageBlockTop.defaultProps = {
  pt: [4, null, "53px"],
  pb: [4],
  px: ["15px", null, "31px", "100px", "200px"],
  borderTopLeftRadius: [null, null, null, null, 1],
  borderTopRightRadius: [null, null, null, null, 1],
};

export { AdvantageBlockTop };
