import styled from "styled-components";
import { AdvantageBlockTop } from "./AdvantageBlockTop";
import themeGet from "@styled-system/theme-get";

const WhyAgentTop = styled(AdvantageBlockTop)`
  border-bottom: 4px solid ${themeGet("colors.cta")};
  background: linear-gradient(
    150deg,
    ${themeGet("colors.gradient.dark")} 0.58%,
    ${themeGet("colors.gradient.light")} 100%
  );
`;

WhyAgentTop.displayName = "WhyAgentTop";

WhyAgentTop.defaultProps = {
  pr: ["15px", null, "31px", null, null],
};

export { WhyAgentTop };
