import styled from "styled-components";
import { space } from "styled-system";
import { Flex } from "../Grid";
import themeGet from "@styled-system/theme-get";

const AdvantageBlockItem = styled(Flex)`
  margin: 0;
  padding: 0;
  &:last-of-type {
    margin-bottom: 26px;
    padding-bottom: 0;
    border-bottom: 0;
    @media screen and (max-width: ${themeGet("breakpoints.md")}) {
      margin-bottom: 0;
    }
  }
  ${space};
`;

AdvantageBlockItem.displayName = "AdvantageBlockItem";

AdvantageBlockItem.defaultProps = {
  mb: [4, null, 26],
};

export { AdvantageBlockItem };
